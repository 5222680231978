import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonTabs } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/home/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* App styling */
import "./app.scss";

import Notifier from "./components/notifications/Notifier";
import ServiceWorkerWrapper from "./components/notifications/ServiceWorkerWrapper";

console.log("I am in APP. Getting started.");

const App: React.FC = () => {
  return (
    <IonApp>
      <ServiceWorkerWrapper />
      <Notifier />
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/home" component={Home} exact />
          <Redirect from="/" to="/home" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
