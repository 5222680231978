import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useIonViewWillEnter } from "@ionic/react";
import "./Player.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { useRef } from "react";

const mapStateToProps = (state: any) => ({
  source: state.audio.source,
});

const mapDispatchToProps = (dispatch: any) => ({});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Player: React.FC<Props> = (props) => {
  const { source, t } = props;
  const myAudio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    console.log("Changed audio source to %s. Refreshing player.", source);
    const audio = myAudio.current;
    audio?.pause();
    if (source !== "") audio?.play();
  }, [source]);

  return (
    <div>
      <audio ref={myAudio} src={source} />
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Player)
);
