export const AUDIO_PLAY = "AUDIO_PLAY";
export const AUDIO_STOP = "AUDIO_STOP";

export function playAudio(source: string) {
  return { type: AUDIO_PLAY, source };
}

export function stopAudio() {
  return { type: AUDIO_STOP };
}
