import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import "./Home.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { Media, MediaSearchRequest } from "../../data/media/Media";
import { searchMedia } from "../../data/media/MediaActions";
import { playAudio, stopAudio } from "../../data/audio/AudioActions";
import Player from "../../components/player/Player";
import LocationTracker from "../../components/location/LocationTracker";
import { calculateDistance, compassHeading } from "../../utils/Geo";
import {
  Plugins,
  MotionOrientationEventResult,
  MotionEventResult,
} from "@capacitor/core";

const mapStateToProps = (state: any) => ({
  media: state.media.items,
  currentLocation: state.location,
});

const mapDispatchToProps = (dispatch: any) => ({
  searchMedia: (request: MediaSearchRequest) => dispatch(searchMedia(request)),
  playAudio: (source: string) => dispatch(playAudio(source)),
  stopAudio: () => dispatch(stopAudio()),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export interface MediaEnhanced {
  media: Media;
  distance: number;
}

const Home: React.FC<Props> = (props) => {
  const {
    searchMedia,
    playAudio,
    stopAudio,
    media,
    currentLocation,
    t,
  } = props;

  const [mediaEnhanced, setMediaEnhanced] = useState<MediaEnhanced[]>([]);

  const { Motion } = Plugins;

  /**
   * Whenever the currentLocation (GPS location) is updated, another server call to
   * retrieve the media is performed.
   */
  useEffect(() => {
    if (currentLocation !== null) {
      if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
        var request: MediaSearchRequest = {
          longitude: String(currentLocation.longitude),
          latitude: String(currentLocation.latitude),
          radius: 300,
        };
        searchMedia(request);
      }
    }
  }, [currentLocation]);

  /**
   * Update the enhanced media once the media is updated (by a server call in the background).
   * The distance is added and the media items are sorted according to their distance.
   */
  useEffect(() => {
    if (currentLocation !== null && media !== null) {
      var newMediaEnhanced = new Array<MediaEnhanced>();
      media.forEach((m: Media) => {
        let newMedia: MediaEnhanced = {
          media: m,
          distance: Math.round(
            calculateDistance(
              m.geoDetails.latitude,
              m.geoDetails.longitude,
              currentLocation.latitude,
              currentLocation.longitude
            )
          ),
        };
        newMediaEnhanced.push(newMedia);
      });
      newMediaEnhanced.sort((a, b) => {
        return a.distance - b.distance;
      });
      setMediaEnhanced(newMediaEnhanced);
    }
  }, [media]);

  const startPlayback = (source: string) => {
    console.log("Start new playback with %s", source);
    playAudio(source);
  };

  const askForPermission = () => {
    if (
      DeviceMotionEvent &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      DeviceMotionEvent.requestPermission()
        .then((permissionState) => {
          console.log("Allowed access");
          Motion.addListener(
            "orientation",
            (event: MotionOrientationEventResult) => {
              console.log("Orientation");
              console.log(event);
              console.log("Compass orientation");
              console.log(compassHeading(event.alpha, event.beta, event.gamma));
            }
          );
        })
        .catch(console.error);
    }
  };

  return (
    <IonPage id="homeView">
      <IonContent>
        <IonList>
          {mediaEnhanced.map((m: MediaEnhanced, index: number) => (
            <IonItem
              key={m.media.uuid}
              onClick={() => startPlayback(m.media.mediaLocation)}
            >
              <IonLabel>{m.media.mediaName}</IonLabel>
              <IonLabel>{m.distance}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        <IonButton onClick={() => stopAudio()}>Stop</IonButton>
        <IonButton onClick={() => askForPermission()}>
          Give permission
        </IonButton>
      </IonContent>
      <Player />
      <LocationTracker />
    </IonPage>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
