import React, { FC, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import * as serviceWorker from "../../serviceWorker";

const ServiceWorkerWrapper: FC = () => {
  const [showUpdateMessage, setShowUpdateMessage] = React.useState(false);
  const [
    waitingWorker,
    setWaitingWorker,
  ] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = async (registration: ServiceWorkerRegistration) => {
    setShowUpdateMessage(true);
    setWaitingWorker(registration.waiting);
    //await registration.update();
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const handleClose = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowUpdateMessage(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showUpdateMessage}
      autoHideDuration={5000}
      onClose={handleClose}
      message="App updated to latest version!"
      onClick={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    />
  );
};

export default ServiceWorkerWrapper;
