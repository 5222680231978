import { LOCATION_UPDATE } from "./LocationActions";

interface State {
  longitude: Number;
  latitude: Number;
}

const initialStateAudio: State = {
  longitude: 0,
  latitude: 0,
};

export const location = (state: State = initialStateAudio, action: any) => {
  switch (action.type) {
    case LOCATION_UPDATE:
      return Object.assign({}, state, {
        longitude: action.longitude,
        latitude: action.latitude,
      });
    default:
      return state;
  }
};
