import {
  SEARCH_MEDIA_REQUEST,
  SEARCH_MEDIA_FAILURE,
  SEARCH_MEDIA_SUCCESS,
} from "./MediaActions";

export interface MediaSearchRequest {
  longitude: string;
  latitude: string;
  radius: number;
}

export interface GeoDetails {
  longitude: number;
  latitude: number;
}

export interface Media {
  geoHashShort: string;
  uuid: string;
  mediaName: string;
  mediaLocation: string;
  geoDetails: GeoDetails;
}

interface State {
  loading: boolean;
  items: Media[];
}

const initialStateMedia: State = {
  loading: false,
  items: [],
};

export const media = (state: State = initialStateMedia, action: any) => {
  switch (action.type) {
    case SEARCH_MEDIA_REQUEST:
      return Object.assign({}, state, { loading: true });
    case SEARCH_MEDIA_FAILURE:
      return Object.assign({}, state, { loading: false });
    case SEARCH_MEDIA_SUCCESS:
      var media: Media[] = action.response.data;
      // enhance devices with UI state
      return Object.assign({}, state, {
        items: media,
        loading: false,
        invalid: {},
      });
    default:
      return state;
  }
};
