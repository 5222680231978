import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { useRef } from "react";
import {
  Geolocation,
  Plugins,
  MotionOrientationEventResult,
  MotionEventResult,
} from "@capacitor/core";
import { updateLocation } from "../../data/location/LocationActions";

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  updateLocation: (latitude: Number, longitude: Number) =>
    dispatch(updateLocation(latitude, longitude)),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const LocationTracker: React.FC<Props> = (props) => {
  const { updateLocation, t } = props;
  const myAudio = useRef<HTMLAudioElement>(null);
  const { Motion } = Plugins;

  const timerUpdateInterval = 5000;

  useEffect(() => {
    let timer = setInterval(() => {
      getLocation();
    }, timerUpdateInterval);
    return () => clearInterval(timer);
  });

  async function getLocation() {
    try {
      const position = await Geolocation.getCurrentPosition();
      console.log(
        "Retrieved position: " +
          position.coords.latitude +
          ", " +
          position.coords.longitude
      );
      updateLocation(position.coords.latitude, position.coords.longitude);
    } catch (e) {
      console.log("Get gelocation failed");
      console.error(e);
    }
  }

  return <div></div>;
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LocationTracker)
);
