import { CALL_API, apiCall } from "../../common/api";
import { MediaSearchRequest } from "./Media";

export const SEARCH_MEDIA_REQUEST = "SEARCH_MEDIA_REQUEST";
export const SEARCH_MEDIA_FAILURE = "SEARCH_MEDIA_FAILURE";
export const SEARCH_MEDIA_SUCCESS = "SEARCH_MEDIA_SUCCESS";

export const searchMedia = (request: MediaSearchRequest) => ({
  [CALL_API]: {
    types: [SEARCH_MEDIA_REQUEST, SEARCH_MEDIA_SUCCESS, SEARCH_MEDIA_FAILURE],
    apiName: "ExplrAPI",
    path: "/media/search",
    method: "post",
    myInit: {
      headers: {},
      body: request,
      response: true,
    },
  },
});
