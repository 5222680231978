import { AUDIO_PLAY, AUDIO_STOP } from "./AudioActions";

interface State {
  source: String;
}

const initialStateAudio: State = {
  source: "",
};

export const audio = (state: State = initialStateAudio, action: any) => {
  switch (action.type) {
    case AUDIO_PLAY:
      return Object.assign({}, state, { source: action.source });
    case AUDIO_STOP:
      return Object.assign({}, state, { source: "" });
    default:
      return state;
  }
};
