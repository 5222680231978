import { combineReducers } from "redux";
import { media } from "./data/media/Media";
import { audio } from "./data/audio/Audio";
import { location } from "./data/location/Location";
import { notifications } from "./components/notifications/Notifications";

const appReducers = combineReducers({
  media,
  audio,
  location,
  notifications,
});

export default appReducers;
