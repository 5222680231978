import { Auth } from "aws-amplify";

export const configDev = {
  aws_project_region: "eu-central-1",
  API: {
    endpoints: [
      {
        name: "ExplrAPI",
        endpoint:
          "https://krvopdedka.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            "x-api-key": "DkO8Ejauev5GIsNJWjPHl9cA0eqK6qXE7n4Gtmrd",
          };
        },
      },
    ],
  },
};

// TODO: Change once prod is available
export const configProd = {
  aws_project_region: "eu-central-1",
  API: {
    endpoints: [
      {
        name: "ExplrAPI",
        endpoint:
          "https://krvopdedka.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            "x-api-key": "DkO8Ejauev5GIsNJWjPHl9cA0eqK6qXE7n4Gtmrd",
          };
        },
      },
    ],
  },
};
